function ListingItem() {
    this.colourways = document.querySelector('.product-listing__colourways');
    this.items = document.querySelectorAll('.product-listing__colourways__item');

    this.init = () => {
        this.colourways = document.querySelector('.product-listing__colourways');
        this.items = document.querySelectorAll('.product-listing__colourways__item');

        if (!this.colourways) {
            return;
        }

        this.watchToggle();
    }

    // Watch for click events
    this.watchToggle = () => {
        this.items.forEach((item, i) => {
            item.addEventListener('click', this.select.bind(this));
        });
    }

    this.select = (e) => {
        const element = e.currentTarget;
        const title = element.dataset.title;
        const src = element.dataset.listingSrc;
        const srcRollover = element.dataset.listingSrcRollover;
        const srcset = element.dataset.listingSrcset;
        const srcsetRollover = element.dataset.listingSrcsetRollover;
        const url = element.dataset.url;

        const normalPrice = element.dataset.normalPrice;
        const oldPrice = element.dataset.oldPrice;
        const newPrice = element.dataset.newPrice;
        const fromLabel = element.dataset.fromLabel;

        const listingItem = element.closest('.product-listing');
        const colourways = listingItem.querySelectorAll('.product-listing__colourways__item');
        const listingTitle = listingItem.querySelector('.product-listing__title');
        const listingImage = listingItem.querySelector('.product-listing__image img');
        const listingImageRollover = listingItem.querySelector('.product-listing__image img.rollover');
        const listingLink = listingItem.querySelector('.product-listing__link');
        const listingNormalPrice = listingItem.querySelector('.product-listing__price--normal');
        const listingOldPrice = listingItem.querySelector('.product-listing__price--old');
        const listingNewPrice = listingItem.querySelector('.product-listing__price--new');
        const listingFromLabel = listingItem.querySelector('.product-listing__from');

        colourways.forEach((item, i) => {
            item.classList.remove('product-listing__colourways__item--active');
        });
        element.classList.add('product-listing__colourways__item--active');

        listingImage.setAttribute('src', src);
        listingImage.setAttribute('srcset', srcset);
        listingTitle.innerHTML = title;

        if (listingNormalPrice) {
            listingNormalPrice.innerHTML = normalPrice;
            listingOldPrice.innerHTML = oldPrice;
            listingNewPrice.innerHTML = newPrice;
            listingFromLabel.innerHTML = fromLabel;

            if (!fromLabel) {
                listingFromLabel.classList.add('product-listing__from--empty');
            } else {
                listingFromLabel.classList.remove('product-listing__from--empty');
            }
        }

        if (url) {
            listingLink.setAttribute('href', url);
        }

        if (listingImageRollover && srcsetRollover) {
            listingImageRollover.setAttribute('src', srcRollover);
            listingImageRollover.setAttribute('srcset', srcsetRollover);
        }
    }
}

AndrewMartin.listingItem = new ListingItem();
AndrewMartin.listingItem.init();
